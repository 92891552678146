<template>
  <div class="mb-10">
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Banco</h4>
    </div>
    <div class="flex justify-center my-2">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="text"
        placeholder="Banco..."
        v-model="datoBancarioObjectProp.banco"
      />
    </div>
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Nombre Titular</h4>
    </div>
    <div class="flex justify-center my-2">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="text"
        placeholder="Nombre..."
        v-model="datoBancarioObjectProp.nombre"
      />
    </div>
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Rif</h4>
    </div>
    <div class="flex justify-center my-2">
      <select
        class="text-xl bg-white border-2 border-green-300 rounded-md text-center mr-2 ml-3"
        name=""
        id=""
        v-model="datoBancarioObjectProp.cedula.prefix"
      >
        <option value="V">V</option>
        <option value="J">J</option>
        <option value="G">G</option>
        <option value="E">E</option>
      </select>
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2 mr-3"
        type="text"
        placeholder="RIF..."
        v-model="datoBancarioObjectProp.cedula.numero"
      />
    </div>
    <div class="">
      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2">Tipo Cuenta</h4>
      </div>
      <div class="flex justify-center my-2">
        <select
          class="text-xl bg-white border-2 border-green-300 rounded-md w-11/12 text-center"
          name=""
          id=""
          v-model="datoBancarioObjectProp.tipoCuenta"
        >
          <option value="1">Corriente</option>
          <option value="0">Ahorro</option>
        </select>
      </div>
    </div>
    <div class="">
      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2">Número Cuenta</h4>
      </div>
      <div class="flex justify-center my-2">
        <input
          class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
          type="text"
          placeholder="N° Cuenta..."
          v-model="datoBancarioObjectProp.numeroCuenta"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datoBancarioObjectProp: Object,
  },
};
</script>

<style></style>
