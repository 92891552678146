<template>
  <div class="mb-10">
    <div class="flex justify-center my-2">
      <h4 class="text-xl pl-2 mr-2">Banco</h4>
    </div>
    <div class="flex justify-center">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="text"
        placeholder="Banco..."
        v-model="datoPagoMovilObjectProp.bancoMovil"
      />
    </div>
    <div class="flex justify-center my-2">
      <h4 class="text-xl pl-2 mr-2">Rif</h4>
    </div>
    <div class="flex justify-center">
      <select
        class="text-xl bg-white border-2 border-green-300 rounded-md text-center mr-2 ml-3"
        name=""
        id=""
        v-model="datoPagoMovilObjectProp.cedulaMovil.prefix"
      >
        <option value="V">V</option>
        <option value="J">J</option>
        <option value="G">G</option>
        <option value="E">E</option>
      </select>
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2 mr-3"
        type="text"
        placeholder="RIF..."
        v-model="datoPagoMovilObjectProp.cedulaMovil.numero"
      />
    </div>
    <div class="flex justify-center my-2">
      <h4 class="text-xl pl-2 mr-2">Número Celular</h4>
    </div>
    <div class="flex justify-center">
      <select
        class="text-xl bg-white border-2 border-green-300 rounded-md text-center mr-2 ml-3"
        name=""
        id=""
        v-model="datoPagoMovilObjectProp.numeroTelefono.prefix"
      >
        <option value="0412">0412</option>
        <option value="0414">0414</option>
        <option value="0424">0424</option>
        <option value="0416">0416</option>
        <option value="0426">0426</option>
      </select>
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2 mr-3"
        type="text"
        placeholder="Número Celular..."
        v-model="datoPagoMovilObjectProp.numeroTelefono.numero"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datoPagoMovilObjectProp: Object,
  },
};
</script>

<style></style>
