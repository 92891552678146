<template>
  <div class="">
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Contraseña Actual</h4>
    </div>
    <div class="flex justify-center my-2">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="password"
        name="password_actual"
        id="password_actual"
        placeholder="Ingrese contraseña..."
        v-model="passwordObjectProp.oldPassword"
      />
    </div>
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Contraseña</h4>
    </div>
    <div class="flex justify-center my-2">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="password"
        name="password1"
        id="password1"
        placeholder="Ingrese contraseña..."
        v-model="passwordObjectProp.newPassword"
      />
    </div>
    <div
      v-if="
        passwordObjectProp.newPassword.length >= 1 &&
        passwordObjectProp.newPassword.length < 8
      "
      class="my-1"
    >
      <p class="text-xs text-red-600">
        La contraseña tiene que tener más de 7 caracteres.
      </p>
    </div>
    <div class="flex justify-center">
      <h4 class="text-xl pl-2 mr-2">Confirme Contraseña</h4>
    </div>
    <div class="flex justify-center my-2">
      <input
        class="w-11/12 border-green-300 border-2 text-xl rounded-md pl-2"
        type="password"
        name="password2"
        id="password2"
        placeholder="Confirme contraseña..."
        v-model="passwordObjectProp.confirmPassword"
      />
    </div>
    <div
      v-if="
        passwordObjectProp.confirmPassword.length >= 1 &&
        passwordObjectProp.confirmPassword.length < 8
      "
      class="my-1"
    >
      <p class="mt-1 text-xs text-red-600">
        La contraseña tiene que tener más de 7 caracteres.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passwordObjectProp: Object,
  },
};
</script>

<style></style>
