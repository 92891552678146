<template>
  <div>
    <form
      class="px-2"
      action=""
      method="POST"
      @submit.prevent="updateDataPerfilProveedor"
    >
      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2 mb-6 mt-4">Datos Bancarios 1</h4>
      </div>

      <data-account-bank-component
        :datoBancarioObjectProp="datosBancarioArray[0]"
      />

      <div class="border-b-2 my-4"></div>

      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2 mb-6">Datos Bancarios 2</h4>
      </div>

      <data-account-bank-component
        :datoBancarioObjectProp="datosBancarioArray[1]"
      />

      <div class="border-b-2 my-4"></div>

      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2 mb-4">Datos Pago Movil</h4>
      </div>
      <pago-movil-component :datoPagoMovilObjectProp="datosBancarioArray[2]" />
      <div class="border-b-2 my-4"></div>
      <div class="flex justify-center">
        <h4 class="text-xl pl-2 mr-2 mb-6">Cambio Contraseña</h4>
      </div>
      <password-component :passwordObjectProp="passwordObject" />
      <div class="flex justify-center my-12">
        <button
          class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
          type="submit"
        >
          Guardar Cambios
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import dataAccountBankComponent from "../components/dataAccountBankComponent.vue";
import PagoMovilComponent from "../components/pagoMovilComponent.vue";
import PasswordComponent from "../components/passwordComponent.vue";
import getIdMixin from "../mixins/getIdMixin";

export default {
  components: {
    dataAccountBankComponent,
    PagoMovilComponent,
    PasswordComponent,
  },
  data() {
    return {
      datosBancarioArray: [],
      passwordObject: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  mixins: [getIdMixin],
  async created() {
    await this.dataPerfilProveedor();
  },
  methods: {
    prefixString(arg, number) {
      let objetoResultado = { prefix: "", numero: "" };
      objetoResultado.prefix = arg.substring(0, number);
      objetoResultado.numero = arg.substring(number, arg.length);
      return objetoResultado;
    },
    objectMutationResult(dataPerfilArray) {
      return {
        nombre_1: dataPerfilArray[0].nombre,
        cedula_1:
          dataPerfilArray[0].cedula.prefix + dataPerfilArray[0].cedula.numero,
        banco_1: dataPerfilArray[0].banco,
        numero_cuenta_1: dataPerfilArray[0].numeroCuenta,
        tipo_cuenta_1: dataPerfilArray[0].tipoCuenta,
        nombre_2: dataPerfilArray[1].nombre,
        cedula_2:
          dataPerfilArray[1].cedula.prefix + dataPerfilArray[1].cedula.numero,
        banco_2: dataPerfilArray[1].banco,
        numero_cuenta_2: dataPerfilArray[1].numeroCuenta,
        tipo_cuenta_2: dataPerfilArray[1].tipoCuenta,
        banco_movil: dataPerfilArray[2].bancoMovil,
        numero_telefono:
          dataPerfilArray[2].numeroTelefono.prefix +
          dataPerfilArray[2].numeroTelefono.numero,
        cedula_movil:
          dataPerfilArray[2].cedulaMovil.prefix +
          dataPerfilArray[2].cedulaMovil.numero,
      };
    },
    dataValidationBankAccount(data) {
      let emptyAll = false;
      let fullAll = false;

      if (
        data.nombre === "" &&
        data.cedula.numero === "" &&
        data.numeroCuenta === ""
      ) {
        emptyAll = true;
      }
      if (
        data.nombre != "" &&
        data.cedula.numero != "" &&
        data.numeroCuenta != ""
      ) {
        fullAll = true;
      }

      if (!emptyAll && !fullAll) {
        return false;
      } else {
        return true;
      }
    },
    dataValidationPagoMovil(data) {
      let emptyAll = false;
      let fullAll = false;

      if (
        data.bancoMovil === "" &&
        data.cedulaMovil.numero === "" &&
        data.numeroTelefono.numero === ""
      ) {
        emptyAll = true;
      }
      if (
        data.bancoMovil != "" &&
        data.cedulaMovil.numero != "" &&
        data.numeroTelefono.numero != ""
      ) {
        fullAll = true;
      }

      if (!emptyAll && !fullAll) {
        return false;
      } else {
        return true;
      }
    },
    passwordValidation() {
      let passwordValidation = {
        lengthCondition: true,
        equalconfirmPassword: true,
      };
      if (
        this.passwordObject.newPassword.length < 8 ||
        this.passwordObject.confirmPassword.length < 8
      ) {
        passwordValidation.lengthCondition = false;
      }
      if (
        this.passwordObject.newPassword != this.passwordObject.confirmPassword
      ) {
        passwordValidation.equalconfirmPassword = false;
      }
      return passwordValidation;
    },
    validateAllData() {
      let dataValidated = true;
      let passwordValidated = this.passwordValidation();

      if (!this.dataValidationBankAccount(this.datosBancarioArray[0])) {
        dataValidated = false;
        alert(
          "Algunos campos para los Datos Bancarios 1 no son validos. Todos los campos tienen que estar llenos. En caso que no desee borrar la información de los datos bancarios deje todos los campos vacios."
        );
      }

      if (!this.dataValidationBankAccount(this.datosBancarioArray[1])) {
        dataValidated = false;
        alert(
          "Algunos campos para los Datos Bancarios 2 no son validos. Todos los campos tienen que estar llenos. En caso que no desee borrar la información de los datos bancarios deje todos los campos vacios."
        );
      }

      if (!this.dataValidationPagoMovil(this.datosBancarioArray[2])) {
        dataValidated = false;
        alert(
          "Algunos campos para Pago Movil no son validos. Todos los campos tienen que estar llenos. En caso que no desee borrar la información de los datos de pago movil deje todos los campos vacios."
        );
      }

      if (
        this.passwordObject.newPassword != "" &&
        this.passwordObject.oldPassword != "" &&
        this.passwordObject.confirmPassword != ""
      ) {
        if (
          !passwordValidated.equalconfirmPassword &&
          !passwordValidated.lengthCondition
        ) {
          dataValidated = false;
          alert(
            "La nueva contraseña no coincide con la confirmación y no cumple con el minimo de caracteres."
          );
        }
        if (
          passwordValidated.equalconfirmPassword &&
          !passwordValidated.lengthCondition
        ) {
          dataValidated = false;
          alert("La nueva contraseña no coincide con el minimo de caracteres.");
        }

        if (
          !passwordValidated.equalconfirmPassword &&
          passwordValidated.lengthCondition
        ) {
          dataValidated = false;
          alert("La nueva contraseña no coincide con la confirmación.");
        }
      }
      return dataValidated;
    },
    nullToEmptyString(arg) {
      if (arg === null) {
        return "";
      }
      return arg;
    },
    async dataPerfilProveedor() {
      this.$store.state.isLoading = true;
      if (this.$store.state.user.id == 0) {
        await this.getUserId();
      }
      await this.$apollo
        .query({
          query: require("@/graphql/queries/dataPerfilProveedorQuery.gql"),
          variables: {
            id: this.$store.state.user.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let cedula;
          let telefono;
          //Datos bancarios 1
          cedula = this.prefixString(
            data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
              .cedula_1,
            1
          );
          cedula.numero = this.nullToEmptyString(cedula.numero);

          this.datosBancarioArray.push({
            nombre: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .nombre_1
            ),
            cedula: cedula,
            banco: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .banco_1
            ),
            numeroCuenta: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .numero_cuenta_1
            ),
            tipoCuenta: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .tipo_cuenta_1
            ),
          });

          //Datos bancarios 2
          cedula = this.prefixString(
            data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
              .cedula_2,
            1
          );
          cedula.numero = this.nullToEmptyString(cedula.numero);
          this.datosBancarioArray.push({
            nombre: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .nombre_2
            ),
            cedula: cedula,
            banco: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .banco_2
            ),
            numeroCuenta: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .numero_cuenta_2
            ),
            tipoCuenta: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .tipo_cuenta_2
            ),
          });
          //Datos pago movil
          telefono = this.prefixString(
            data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
              .numero_telefono,
            4
          );
          telefono.numero = this.nullToEmptyString(telefono.numero);
          cedula = this.prefixString(
            data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
              .cedula_movil,
            1
          );
          cedula.numero = this.nullToEmptyString(cedula.numero);
          this.datosBancarioArray.push({
            bancoMovil: this.nullToEmptyString(
              data.data.dataPerfilProveedorQuery[0].user.datos_bancarios[0]
                .banco_movil
            ),
            numeroTelefono: telefono,
            cedulaMovil: cedula,
          });
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
    async updateDataPerfilProveedor() {
      if (this.validateAllData()) {
        this.$store.state.isLoading = true;
        await this.$apollo
          .mutate({
            mutation: require("@/graphql/mutations/updateDatosBancariosProveedor.gql"),
            variables: {
              id: this.$store.state.user.id,
              data: this.objectMutationResult(this.datosBancarioArray),
            },
          })
          .then(() => {
            this.$store.state.isLoading = false;
            alert("¡Datos bancarios actualizados!");
            if (
              this.passwordObject.newPassword != "" &&
              this.passwordObject.oldPassword != "" &&
              this.passwordObject.confirmPassword != ""
            ) {
              this.passwordUpdateMutation();
            }
          })
          .catch((error) => {
            this.$store.state.isLoading = false;
            console.error(error);
            alert("Error al actualizar datos bancarios, vuelva a intentarlo.");
          });
      }
    },
    async passwordUpdateMutation() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/updatePassword.gql"),
          variables: {
            input: {
              old_password: this.passwordObject.oldPassword,
              password: this.passwordObject.newPassword,
              password_confirmation: this.passwordObject.confirmPassword,
            },
          },
        })
        .then(() => {
          this.$store.state.isLoading = false;
          alert("¡Contraseña actualizada!");
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.error(error);
          alert(error);
        });
    },
  },
};
</script>

<style></style>
